import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/appConfig/appConfig.service';

@Injectable({
	providedIn: 'root',
})
export class ApiBaseUrl {
	getOrganizationApiUrl(apiName: string, queryString?: any, _type?: boolean) {
		let apiUrl = '';
		if (AppConfigService.settings?.url?.useMock) {
			apiUrl = AppConfigService.settings?.url?.jsonServerUrl;
		}
		// apiUrl = AppConfigService.settings.url.organisationUrl;
		else apiUrl = AppConfigService.settings?.url?.organisationUrl ? AppConfigService.settings?.url?.organisationUrl : '';
		//apiUrl = apiName.toLowerCase().indexOf('api/deal/') < 0 ? apiUrl:'https://localhost:4001'

		apiUrl += `/${apiName}`;
		if (queryString) {
			apiUrl += `?${queryString}`;
		}
		return apiUrl;
	}
}
