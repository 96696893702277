import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@Pipe({
	name: 'userTimeZoneFormatPipe',
	pure: true,
})

export class UserTimeZoneFormatPipe implements PipeTransform {
	localTimeZone: string | undefined;
	transform(values: string): any {
		if (values !== null) {
			const timeZone = dayjs.tz.guess();
			if (values.toLocaleLowerCase().indexOf('z') < 0) {
				values = values + 'Z';
			}
			const localTimeZone: string | null = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);
			this.localTimeZone = String(localTimeZone.match(/\b(\w)/g)?.join(''));
			const localDateTime = dayjs.utc(new Date(values)).tz(timeZone).format('YYYY-MM-DD hh:mm A') + ' ' + this.localTimeZone;
			return localDateTime;
		}
		else {
			return values;
		}
	}
}
