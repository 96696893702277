import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpRequestService } from '@core/services/http-request/http-request.service';
import { ApiBaseUrl } from '@shared/services/apiBaseUrl.service';
import { CoreConstantsApi } from '@core/core.constants';
import { PasswordCheck, PasswordCheckResponse } from '../models/ForgotPassDetails.model';

@Injectable({
	providedIn: 'root',
})
export class ForgotPasswordService {
	private forgotPasswordDetails = new BehaviorSubject<any>('');

	userName = this.forgotPasswordDetails.asObservable();

	constructor(public httpService: HttpRequestService, private apiService: ApiBaseUrl) {}

	setForgotUserInfo(userName: string): void {
		this.forgotPasswordDetails.next(userName);
	}

	getForgotUserInfo(): Observable<string> {
		return this.userName;
	}

	verifyForgotPreviousPassword(passwordDetails: PasswordCheck): Observable<PasswordCheckResponse> {
		const apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.previousPasswordCheck);
		return this.httpService.postData(apiurl, passwordDetails);
	}

	onAfterPasswordReset(): Observable<any> {
        let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.logout);
        return this.httpService.postData(apiurl, null);
    }
}
